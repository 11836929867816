<template>
    <div>
        <template v-for="(measurement, i) in measurementsFormatted">
            <component
                :is="measurement.component"
                v-if="measurement.component"
                :key="i"
                :is-shared="isShared"
                :measurement="measurement"
                :tracker="tracker"
            />

            <MeasurementItem
                v-else
                :key="i"
                :title="measurement.title"
                :datetime="measurement.lastUpdated"
                :link-to="measurement.link"
            >
                <template #icon>
                    <component
                        :is="measurement.icon"
                        v-bind="measurement.iconOptions"
                        width="24"
                        height="24"
                    />
                </template>

                <template #content>
                    {{ measurement.label }}:

                    <h3>{{ measurement.value }} {{ measurement.unit }}</h3>
                </template>
            </MeasurementItem>
        </template>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import { evaluate } from 'mathjs'

import { languageHelper } from '@/utils'
import BatteryIcon from './icons/BatteryIcon'
import BinFillIcon from './icons/BinFillIcon'
import BooleanStatusMeasurementItem from './BooleanStatusMeasurementItem'
import BrightnessIcon from './icons/BrightnessIcon'
import CO2Icon from './icons/CO2Icon'
import CylinderIcon from './icons/CylinderIcon'
import DistanceIcon from './icons/DistanceIcon'
import DriverMeasurementFragment from './DriverMeasurementFragment'
import DropIcon from './icons/DropIcon'
import FlashIcon from './icons/FlashIcon'
import FuelCanIcon from './icons/FuelCanIcon'
import LineChartIcon from './icons/LineChartIcon'
import MeasurementItem from './MeasurementItem'
import SaltSpreadingMeasurementFragment from './SaltSpreadingMeasurementFragment'
import ScalesIcon from './icons/ScalesIcon'
import SpeedIcon from './icons/SpeedIcon'
import StopwatchIcon from './icons/StopwatchIcon'
import TemperatureMoistureMeasurementFragment from './TemperatureMoistureMeasurementFragment'
import ThermometerIcon from './icons/ThermometerIcon'
import TruckRemainingStatsMeasurementFragment from './TruckRemainingStatsMeasurementFragment'
import TruckTachographMeasurementFragment from './TruckTachographMeasurementFragment'
import VocIcon from './icons/VocIcon'
import WaterLevelIcon from './icons/WaterLevelIcon'
import WindIcon from './icons/WindIcon'

const itemComponents = {
    'boolean-status': BooleanStatusMeasurementItem,
    'salt-spreading': SaltSpreadingMeasurementFragment,
    'temperature-moisture': TemperatureMoistureMeasurementFragment,
    'truck-remaining-stats': TruckRemainingStatsMeasurementFragment,
    'truck-tachograph': TruckTachographMeasurementFragment,
    driver: DriverMeasurementFragment,
}

const icons = {
    battery: BatteryIcon,
    battery_status: BatteryIcon,
    battery_voltage: BatteryIcon,
    brightness: BrightnessIcon,
    co2: CO2Icon,
    distance: DistanceIcon,
    external_voltage: FlashIcon,
    fill_level: BinFillIcon,
    fuel_level: FuelCanIcon,
    fuel_rate: FuelCanIcon,
    humidity: DropIcon,
    level: WaterLevelIcon,
    mass: ScalesIcon,
    odometer: DistanceIcon,
    running_time: StopwatchIcon,
    speed: SpeedIcon,
    t1: ThermometerIcon,
    t2: ThermometerIcon,
    t3: ThermometerIcon,
    t4: ThermometerIcon,
    t5: ThermometerIcon,
    t6: ThermometerIcon,
    temperature: ThermometerIcon,
    voc: VocIcon,
    voltage_0: FlashIcon,
    voltage_1: FlashIcon,
    voltage_2: FlashIcon,
    voltage_3: FlashIcon,
    voltage_4: FlashIcon,
    volume: CylinderIcon,
    wind_speed: WindIcon,
}

export default {
    name: 'CustomMeasurement',
    components: {
        MeasurementItem,
    },
    props: {
        isShared: {
            type: Boolean,
            default: false,
        },
        measurements: {
            type: Object,
            required: true,
        },
        tracker: {
            type: Object,
            required: true,
        },
    },
    computed: {
        measurementsFormatted() {
            const entries = Object.entries(this.measurements)
            return entries.reduce((acc, [key, cur]) => {
                if (cur.measurements.some(item => !this.sensorData[item])) {
                    return acc
                }

                const value = this.getValue(
                    cur.measurements,
                    cur.props?.converter
                )
                const label = this.getLabel(
                    cur.props?.label_opposite && !value
                        ? cur.props.label_opposite
                        : cur.props?.label
                )
                const lastUpdated = this.getLastUpdatedTime(cur.measurements)

                return acc.concat({
                    component: itemComponents[cur.props?.component],
                    icon: icons[cur.props?.icon] || LineChartIcon,
                    label,
                    lastUpdated,
                    link: this.isShared
                        ? undefined
                        : cur.props?.route
                        ? {
                              name: cur.props.route.name,
                              params: {
                                  id: this.tracker.id,
                                  ...cur.props.route.params,
                              },
                          }
                        : cur.chart?.length
                        ? {
                              name: 'chartsMeasurements',
                              params: { id: this.tracker.id, measurement: key },
                          }
                        : undefined,
                    title: this.getTitle(cur.measurements, lastUpdated),
                    unit: cur.props?.unit,
                    value,
                    ...(cur.measurements.length === 1 && {
                        iconOptions: this.getIconOptions(
                            cur.measurements[0],
                            value
                        ),
                        label: label || cur.measurements[0],
                    }),
                })
            }, [])
        },
        sensorData() {
            return (
                this.tracker.sensor_data ??
                this.tracker.asset_details.sensor_data
            )
        },
    },
    methods: {
        getIconOptions(key, value) {
            switch (key) {
                case 'battery':
                case 'battery_percentage':
                    return {
                        batteryLevelInPercent: value,
                    }
                case 'battery_status':
                    return {
                        batteryStatus: value,
                    }
                case 'battery_voltage':
                    return {
                        batteryLevelInPercent: 100,
                    }
                case 'fill_level':
                    return {
                        fullness: value,
                    }
            }
        },
        getLabel(translations) {
            return (
                translations?.[this.$i18n.locale] ||
                translations?.[languageHelper.defaultLanguage]
            )
        },
        getLastUpdatedTime(keys) {
            return keys.reduce(
                (acc, key) =>
                    !acc || this.sensorData[key].last_update > acc
                        ? this.sensorData[key].last_update
                        : acc,
                undefined
            )
        },
        getTitle(keys, time) {
            const title = keys.join(' | ')
            if (time) {
                const label = this.$t('shared.lastMeasurement')
                const timeFormatted = moment(time).format('DD.MM.YYYY HH:mm')
                return `${title} | ${label}: ${timeFormatted}`
            }
            return title
        },
        getValue(keys, converter) {
            if (!converter) {
                return this.sensorData[keys[0]].value
            }
            const args = keys.reduce(
                (acc, key) => ({ ...acc, [key]: this.sensorData[key].value }),
                {}
            )
            return evaluate(converter, args)
        },
    },
}
</script>
