<template>
    <MeasurementItem
        v-if="driverName"
        :title="driverName.title"
        :datetime="driverName.lastUpdated"
        :link-to="
            !isShared
                ? {
                      name: 'tachograph',
                      params: { id: tracker.id },
                  }
                : null
        "
    >
        <template #icon>
            <PersonIcon width="24" height="24" />
        </template>

        <template #content>
            <div class="no-padding">
                <h3>{{ driverName.value }}</h3>

                <div v-if="drivingTimeLeft">
                    {{ $t('drivingTimeLeft') }}:
                    <b>{{ drivingTimeLeft.value }}</b>
                </div>

                <div v-if="drivingTime">
                    {{ $t('drivingTime') }}:
                    <b>{{ drivingTime.value }}</b>
                </div>

                <div
                    v-if="tachographOutOfScope"
                    class="l-inline l-center-v l-gap-0"
                >
                    <FilledCircleIcon
                        width="10"
                        height="10"
                        :class="tachographOutOfScope.value ? 'red' : 'green'"
                    />

                    <span>{{ $t('tachographOutOfScope') }}</span>
                </div>

                <div
                    v-if="tachographOverSpeed"
                    class="l-inline l-center-v l-gap-0"
                >
                    <FilledCircleIcon
                        width="10"
                        height="10"
                        :class="tachographOverSpeed.value ? 'red' : 'green'"
                    />

                    <span>{{ $t('tachographOverSpeed') }}</span>
                </div>
            </div>
        </template>
    </MeasurementItem>
</template>

<script>
import MeasurementListHelper from '../mixins/MeasurementListHelper'

import { formatHelper } from '../utils'
import FilledCircleIcon from './icons/FilledCircleIcon'
import MeasurementItem from './MeasurementItem'
import PersonIcon from './icons/PersonIcon'

export default {
    name: 'DriverMeasurementFragment',
    components: {
        FilledCircleIcon,
        MeasurementItem,
        PersonIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        driverName() {
            return this.getMeasurementItem('driver_name')
        },
        drivingTime() {
            if (this.isTruck) {
                return null
            }
            const item = this.getMeasurementItem(
                'driver_1_current_daily_driving_time'
            )
            if (item) {
                item.value = formatHelper.hoursAndMinutesDuration(item.value)
            }
            return item
        },
        drivingTimeLeft() {
            if (this.isTruck) {
                return null
            }
            const item = this.getMeasurementItem(
                'driver_1_remaining_time_current_shift'
            )
            if (item) {
                item.value = formatHelper.hoursAndMinutesDuration(item.value)
            }
            return item
        },
        isTruck() {
            return this.tracker.asset_details?.asset_type_type === 'truck'
        },
        tachographOutOfScope() {
            return this.getMeasurementItem('tachograph_out_of_scope')
        },
        tachographOverSpeed() {
            return this.getMeasurementItem('tachograph_over_speed')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "drivingTime": "Driving time",
        "drivingTimeLeft": "Driving time left",
        "tachographOutOfScope": "Driving time",
        "tachographOverSpeed": "Speed"
    },
    "de": {
        "drivingTime": "Lenkzeit",
        "drivingTimeLeft": "Lenkzeit übrig",
        "tachographOutOfScope": "Lenkzeit",
        "tachographOverSpeed": "Geschwindigkeit"
    },
    "fr": {
        "drivingTime": "Temps de conduite",
        "drivingTimeLeft": "Temps de conduite restant",
        "tachographOutOfScope": "Temps de conduite",
        "tachographOverSpeed": "Vitesse"
    },
    "it": {
        "drivingTime": "Tempo di guida",
        "drivingTimeLeft": "Tempo di guida residuo",
        "tachographOutOfScope": "Tempo di guida",
        "tachographOverSpeed": "Velocità"
    }
}
</i18n>

<style lang="scss" scoped>
.no-padding {
    padding: 0;
}

.green {
    color: $color-green;
}

.red {
    color: $color-red;
}
</style>
