<template>
    <MeasurementItem>
        <template #content>
            <div
                class="no-padding l-full-width l-inline l-gap-2 remaining-stats"
            >
                <div class="l-stack l-gap-1">
                    <span :title="$t('currentShift')">
                        <SteeringWheelIcon width="20" height="20" />
                    </span>
                    <span :title="$t('breakTime')">
                        <TeaCupIcon width="20" height="20" />
                    </span>
                    <span :title="$t('timeUntilRest')">
                        <HistoryClockIcon width="20" height="20" />
                    </span>
                </div>

                <div class="l-stack l-gap-1">
                    <span :title="$t('currentShift0')">
                        {{ currentShiftBounds[0] }}
                    </span>
                    <span :title="$t('breakTime0')">
                        {{ breakTimeBounds[0] }}
                    </span>
                    <span :title="$t('timeUntilRest0')">
                        {{ timeUntilRestBounds[0] }}
                    </span>
                </div>

                <div class="l-grow-1 l-stack l-gap-1">
                    <div class="l-inline l-center-v">
                        <div class="line-progress" :title="$t('currentShift')">
                            <div
                                class="line-progress__value"
                                :class="{
                                    'line-progress__value--full':
                                        currentShiftPercentage >= 100,
                                }"
                                :style="{
                                    width: `${currentShiftPercentage}%`,
                                }"
                            />
                        </div>
                    </div>

                    <div class="l-inline l-center-v">
                        <div class="line-progress" :title="$t('breakTime')">
                            <div
                                class="line-progress__value"
                                :class="{
                                    'line-progress__value--full':
                                        breakTimePercentage >= 100,
                                }"
                                :style="{
                                    width: `${breakTimePercentage}%`,
                                }"
                            />
                        </div>
                    </div>

                    <div class="l-inline l-center-v">
                        <div class="line-progress" :title="$t('timeUntilRest')">
                            <div
                                class="line-progress__value"
                                :class="{
                                    'line-progress__value--full':
                                        timeUntilRestPercentage >= 100,
                                }"
                                :style="{
                                    width: `${timeUntilRestPercentage}%`,
                                }"
                            />
                        </div>
                    </div>
                </div>

                <div class="l-stack l-gap-1">
                    <span :title="$t('currentShift1')">
                        {{ currentShiftBounds[1] }}
                    </span>
                    <span :title="$t('breakTime1')">
                        {{ breakTimeBounds[1] }}
                    </span>
                    <span :title="$t('timeUntilRest1')">
                        {{ timeUntilRestBounds[1] }}
                    </span>
                </div>

                <div class="l-stack l-gap-1">
                    <div
                        class="l-inline l-gap-0"
                        :title="
                            $tc(
                                'remainingDrivingTimes',
                                remainingDrivingTimes
                                    ? remainingDrivingTimes.value
                                    : 0
                            )
                        "
                    >
                        <div
                            v-for="key in [1, 2]"
                            :key="key"
                            class="circle-check"
                            :class="{
                                'circle-check--checked':
                                    remainingDrivingTimes &&
                                    key > 2 - remainingDrivingTimes.value,
                            }"
                        />
                    </div>

                    <div />

                    <div
                        class="l-inline l-gap-0"
                        :title="
                            $tc(
                                'remainingRestPeriods',
                                remainingRestPeriods
                                    ? remainingRestPeriods.value
                                    : 0
                            )
                        "
                    >
                        <div
                            v-for="key in [1, 2, 3]"
                            :key="key"
                            class="circle-check"
                            :class="{
                                'circle-check--checked':
                                    remainingRestPeriods &&
                                    key > 3 - remainingRestPeriods.value,
                            }"
                        />
                    </div>
                </div>
            </div>
        </template>
    </MeasurementItem>
</template>

<script>
import HistoryClockIcon from './icons/HistoryClockIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import SteeringWheelIcon from './icons/SteeringWheelIcon'
import TeaCupIcon from './icons/TeaCupIcon'

export default {
    name: 'TruckRemainingStatsMeasurementFragment',
    components: {
        HistoryClockIcon,
        MeasurementItem,
        SteeringWheelIcon,
        TeaCupIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        breakTimeBounds() {
            return [
                this.formatTime(
                    this.sensorData.driver_1_cumulative_break_time?.value
                ),
                this.formatTime(
                    this.sensorData.driver_1_duration_next_break?.value
                ),
            ]
        },
        breakTimePercentage() {
            const item = this.sensorData.driver_1_cumulative_break_time
            return item ? Math.min((item.value / (45 * 60)) * 100, 100) : 0
        },
        currentShiftBounds() {
            const max = 9 * 60 * 60
            const offset = 1 * 60 * 60
            const bounds = this.sensorData.driver_1_current_daily_driving_time
                ? [
                      this.sensorData.driver_1_current_daily_driving_time.value,
                      max -
                          this.sensorData.driver_1_current_daily_driving_time
                              .value,
                  ]
                : this.sensorData.driver_1_remaining_time_current_shift
                ? [
                      max +
                          offset -
                          this.sensorData.driver_1_remaining_time_current_shift
                              .value,
                      this.sensorData.driver_1_remaining_time_current_shift
                          .value - offset,
                  ]
                : []
            return bounds.map(this.formatTime)
        },
        currentShiftPercentage() {
            const max = 9 * 60 * 60
            const offset = 1 * 60 * 60
            return this.sensorData.driver_1_current_daily_driving_time
                ? Math.min(
                      (this.sensorData.driver_1_current_daily_driving_time
                          .value /
                          max) *
                          100,
                      100
                  )
                : this.sensorData.driver_1_remaining_time_current_shift
                ? Math.min(
                      ((max -
                          this.sensorData.driver_1_remaining_time_current_shift
                              .value) /
                          (max - offset)) *
                          100,
                      100
                  )
                : 0
        },
        remainingDrivingTimes() {
            return this.getMeasurementItem(
                'driver_1_remaining_10h_driving_times'
            )
        },
        remainingRestPeriods() {
            return this.getMeasurementItem(
                'driver_1_remaining_reduced_daily_rest_periods'
            )
        },
        timeUntilRestBounds() {
            const max = 15 * 60 * 60
            const offset = 4 * 60 * 60
            const item = this.sensorData
                .driver_1_time_left_until_new_daily_rest_period
            const bounds = item ? [max - item.value, item.value - offset] : []
            return bounds.map(this.formatTime)
        },
        timeUntilRestPercentage() {
            const max = 15 * 60 * 60
            const offset = 4 * 60 * 60
            const item = this.sensorData
                .driver_1_time_left_until_new_daily_rest_period
            return item
                ? Math.min(((max - item.value) / (max - offset)) * 100, 100)
                : 0
        },
    },
    methods: {
        formatTime(seconds = 0) {
            const secondsAbsolute = Math.abs(seconds)
            const hours = Math.floor(secondsAbsolute / 60 / 60)
            const minutes = Math.round(secondsAbsolute / 60) - hours * 60
            return `${seconds < 0 ? '-' : ''}${hours}:${
                minutes < 10 ? '0' : ''
            }${minutes}`
        },
    },
}
</script>

<i18n>
{
    "en": {
        "breakTime": "Break time",
        "breakTime0": "Cumulative break time",
        "breakTime1": "Next break duration",
        "currentShift": "Driving time",
        "currentShift0": "Total driving time in current shift",
        "currentShift1": "Remaining driving time in current shift",
        "remainingDrivingTimes": "{n} remaining 10-hour driving time | {n} remaining 10-hour driving times",
        "remainingRestPeriods": "{n} remaining reduced daily rest period | {n} remaining reduced daily rest periods",
        "timeUntilRest": "Working time",
        "timeUntilRest0": "Current working time",
        "timeUntilRest1": "Remaining working time"
    },
    "de": {
        "breakTime": "Pause",
        "breakTime0": "Kumulative Pausenzeit",
        "breakTime1": "Länge der nächsten Pause",
        "currentShift": "Lenkzeit",
        "currentShift0": "Gesamtlenkzeit der aktuellen Schicht",
        "currentShift1": "Verbleibende Lenkzeit der aktuellen Schicht",
        "remainingDrivingTimes": "{n} verbleibende 10-Stunden Fahrzeit | {n} verbleibende 10-Stunden Fahrzeiten",
        "remainingRestPeriods": "{n} verbleibende reduzierte tägliche Ruhezeit | {n} verbleibende reduzierte tägliche Ruhezeiten",
        "timeUntilRest": "Arbeitszeit",
        "timeUntilRest0": "Heutige Arbeitszeit",
        "timeUntilRest1": "Verbleibende Arbeitszeit"
    },
    "fr": {
        "breakTime": "Pause",
        "breakTime0": "Temps de pause cumulé",
        "breakTime1": "Durée de la prochaine pause",
        "currentShift": "Temps de conduite",
        "currentShift0": "Temps de conduite total pour le poste actuel",
        "currentShift1": "Temps de conduite restant dans le poste actuel",
        "remainingDrivingTimes": "{n} période de conduite de 10 heures restante | {n} périodes de conduite de 10 heures restantes",
        "remainingRestPeriods": "{n} période de repos quotidien réduite restante | {n} périodes de repos quotidien réduites restantes",
        "timeUntilRest": "Temps de travail",
        "timeUntilRest0": "Temps de travail actuel",
        "timeUntilRest1": "Temps de travail restant"
    },
    "it": {
        "breakTime": "Pausa",
        "breakTime0": "Tempo di pausa cumulativo",
        "breakTime1": "Durata della prossima pausa",
        "currentShift": "Tempo di guida",
        "currentShift0": "Tempo totale di guida nel turno in corso",
        "currentShift1": "Tempo di guida rimanente nel turno in corso",
        "remainingDrivingTimes": "{n} tempo di guida di 10 ore rimanente | {n} tempi di guida di 10 ore rimanenti",
        "remainingRestPeriods": "{n} periodo di riposo giornaliero ridotto rimanente | {n} periodi di riposo giornaliero ridotti rimanenti",
        "timeUntilRest": "Orario di lavoro",
        "timeUntilRest0": "Orario di lavoro attuale",
        "timeUntilRest1": "Orario di lavoro rimanente"
    }
}
</i18n>

<style lang="scss" scoped>
.remaining-stats {
    line-height: 20px;

    & > * {
        & > * {
            height: 20px;
        }
    }

    svg {
        display: block;
        margin: 0 2px;
    }
}

.circle-check {
    margin-top: 6px;
    margin-bottom: 6px;
    width: 8px;
    height: 8px;
    border: 2px solid $color-primary-light;
    border-radius: 50%;
    background-color: $color-primary-light;

    &--checked {
        background-color: transparent;
    }
}

.line-progress {
    position: relative;
    width: 100%;
    height: 6px;
    background-color: $color-gray-light-new;

    &__value {
        width: 0;
        height: 100%;
        background-color: $color-primary-light;

        &--full {
            background-color: $color-accent;
        }
    }
}
</style>
